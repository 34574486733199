import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`r.i.c.e. is a research institute for compassionate economics. We are a non-profit research organization focused on health and well-being in India. Our core focus is on children in rural north India. Our research studies health care at the start of life, sanitation, air pollution, maternal health, social inequality, and other dimensions of population-level social wellbeing.`}</p>
    <p>{`Our team combines the tools and insights from demography, economics, and sociology. Our research is based on large-scale demographic statistics, on specialized surveys that we design, and on qualitative research, especially in the villages of Uttar Pradesh.`}</p>
    <p>{`On this website, you can find our research papers, our datasets (which are available for anyone to use), and our analyses of policies that matter for the health and well-being of children. You can also learn more about our team and read media coverage of our research.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      