import { graphql } from "gatsby"
import HomepageComponent from "../components/homepage"

export default HomepageComponent

// export const query = graphql`
//   query($formatString: String!) {
//     allPost(sort: { fields: date, order: DESC }, limit: 10) {
//       nodes {
//         slug
//         title
//         date(formatString: $formatString)
//         timeToRead
//         description
//         tags {
//           name
//           slug
//         }
//         banner {
//           childImageSharp {
//             fluid(
//               maxWidth: 500,
//               maxHeight: 200,
//               quality: 90
//             ) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }

//   }
// `
