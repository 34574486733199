/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Grid, Box, Button } from '@theme-ui/components'
import { Link } from 'gatsby'
import Layout from './layout'

// @ts-ignore
import Home1 from '../texts/home1.mdx'

import Title from './title'
import Listing from './listing'
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config'
import replaceSlashes from '../utils/replaceSlashes'

type Props = {
  pageContext
}

const Homepage = ({ posts }) => {
  const { basePath, blogPath } = useMinimalBlogConfig()
  return (
    <Layout>
      <section
        aria-label='Homepage Containers up to Posts'
        sx={{
          mb: [3, 4, 5],
          p: {
            fontSize: [1, 2, 3],
            mt: 2
          },
          textAlign: `center`,
          iframe: {
            objectFit: 'contain',
            width: '100%',
            height: ['360px', '430px', '540px']
          },
          img: {
            objectFit: 'cover',
            objectPosition: '50% 30%',
            borderRadius: '5px',
            width: '100%',
            height: 'auto'
          }
        }}>
        <section
          aria-label='Banner'
          sx={{
            '& > div': {
              position: 'relative',
              width: '100%'
            },
            mb: [3, 4, 5]
          }}>
          <div>
            <img src='./images/banner/banner9.jpg'></img>
          </div>
        </section>

        <section
          aria-label='Intro Panel'
          sx={{
            // img: {
            //   objectPosition: '20% 50%',
            //   height: '100%',
            // },
            mb: [3, 4, 5],
            padding: '3%',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: 'tertiary'
            // borderWidth: '1px',
          }}>
          <Grid
            aria-label='Intro Panel'
            gap={4}
            columns={[1, 1, 2]}
            sx={{
              mb: [1, 1, 1]
            }}>
            <Grid
              sx={{
                display: `flex`,
                justifyContent: `space-between`,
                flexFlow: `wrap`,
                img: {
                  objectPosition: '50% 50%',
                  height: '100%'
                }
                // mr: [1, 1, 4]
              }}>
              <Box>
                <img
                  title='Enviroment'
                  alt='Enviroment'
                  src='./images/banner/banner6.jpg'></img>
              </Box>
              <Box>
                <img
                  title='Maternal Health'
                  alt='Maternal Health'
                  src='./images/banner/banner7.jpg'></img>
              </Box>
              <Box>
                <img
                  title='Sanitation'
                  alt='Sanitation'
                  src='./images/banner/banner8.jpg'></img>
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  textAlign: `left`
                }}>
                <Home1 />
                <a href='/about' target=''>
                  <Button
                    variant='secondary'
                    sx={{
                      cursor: 'pointer',
                      width: '100%'
                    }}>
                    Learn more about r.i.c.e.
                  </Button>
                </a>
              </Box>
            </Grid>
          </Grid>
        </section>

        <section
          aria-label='Research Published In'
          sx={{
            // img: {
            //   height: '100%',
            // },
            mb: [3, 4, 5],
            padding: '2%',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: 'tertiary'
          }}>
          <h2
            sx={{
              mb: [2, 3, 4],
              fontWeight: `medium`,
              fontSize: [3, 4],
              fontFamily: `heading`
              // color: `heading`
            }}>
            Research Published In
          </h2>
          <Grid
            aria-label='Research Published In'
            gap={3}
            columns={[4, 4, 8]}
            sx={
              {
                // mb: [3, 4, 5],
              }
            }>
            <Box>
              <a href='/research/local-social-inequality-economic-inequality-and-disparities-in-child-height-in-india/'>
                <img title='Demography' src='./images/logos/journal1.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/revisiting-open-defecation-evidence-from-a-panel-survey-in-rural-north-india-2014-18/'>
                <img
                  title='Economic & Political Weekly'
                  src='./images/logos/journal2.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/underweight-pregnant-maternity-entitlements-and-weight-gain-during-pregnancy/'>
                <img
                  title='Indian Journal of Human Development'
                  src='./images/logos/journal3.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/implications-of-wash-benefits-trials-for-water-and-sanitation/'>
                <img
                  title='The Lancet Global Health'
                  src='./images/logos/journal4.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/mobile-phone-survey-methods-for-measuring-social-discrimination/'>
                <img title='NCAER' src='./images/logos/journal5.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/an-experiment-with-air-purifiers-in-delhi-during-winter-2015-2016/'>
                <img title='PLOS One' src='./images/logos/journal6.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/pre-pregnancy-body-mass-and-weight-gain-during-pregnancy-in-india-sub-saharan-africa/'>
                <img title='PNAS' src='./images/logos/journal7.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/research/where-there-is-smoke-solid-fuel-externalities-gender-and-adult-respiratory-health-in-india/'>
                <img
                  title='Population and Environment'
                  src='./images/logos/journal8.png'></img>
              </a>
            </Box>
          </Grid>
        </section>

        <section
          aria-label='Press Coverage'
          sx={{
            img: {
              height: '100%'
            },
            mb: [3, 4, 5],
            padding: '2%',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: 'tertiary'
          }}>
          <h2
            sx={{
              mb: [2, 3, 4],
              fontWeight: `medium`,
              fontSize: [3, 4],
              fontFamily: `heading`
              // color: `heading`
            }}>
            Media Coverage In
          </h2>
          <Grid
            aria-label='Press Coverage'
            gap={3}
            columns={[4, 4, 8]}
            sx={
              {
                // mb: [3, 4, 5],
              }
            }>
            <Box>
              <a href='/news_post/status-check-swachh-bharat-mission-to-complete-5-years-on-october-2-rukmini-s-economic-times/'>
                <img
                  title='The Economic Times'
                  src='./images/logos/media1.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/caste-is-stunting-all-of-indias-children-diane-coffey-and-dean-spears-foreign-policy/'>
                <img
                  title='Foreign Policy'
                  src='./images/logos/media2.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/cooking-with-gas-not-wood-sangita-vyas-and-aashish-gupta-the-hindu/'>
                <img title='The Hindu' src='./images/logos/media3.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/child-height-in-india-new-data-familiar-challenges-diane-coffey-and-dean-spears-ideas-for-india/'>
                <img
                  title='Ideas for India'
                  src='./images/logos/media4.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/poor-sanitation-in-india-may-afflict-well-fed-children-with-malnutrition/'>
                <img
                  title='The New York Times'
                  src='./images/logos/media5.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/untouchability-still-high-in-uttar-pradesh-rajasthan-and-delhi-finds-survey-scroll-staff-scroll-in/'>
                <img title='Scroll' src='./images/logos/media6.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/why-using-patriarchal-messaging-to-promote-toilets-is-a-bad-idea-the-wire/'>
                <img title='The Wire' src='./images/logos/media7.png'></img>
              </a>
            </Box>
            <Box>
              <a href='/news_post/protecting-health-workers-protective-gear-is-not-enough-infection-control-practices-are-critical-payal-hathi-and-nikhil-srivastav-the-times-of-india/'>
                <img
                  title='The Times of India'
                  src='./images/logos/media8.png'></img>
              </a>
            </Box>
          </Grid>
        </section>
      </section>

      <section aria-label='Posts'>
        <Title text='Latest Content'>
          <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>
            Read all posts
          </Link>
        </Title>

        <Listing
          posts={posts}
          sx={{
            mb: [4, 5, 6]
          }}
        />
      </section>
    </Layout>
  )
}

export default ({ pageContext }: Props) => {
  const { posts } = pageContext
  return <Homepage posts={posts} />
}

{
  /* <figure>
  <img title="Enviroment" alt="Enviroment" src='./images/banner/banner6.jpg'></img>
  <figcaption>Environment</figcaption>
</figure> */
}
